import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { CheckboxesInput, CheckboxesInputProps } from '@vklink/components-shared';

export interface CheckboxesOptions {
  label: string;
  value: string;
  title?: string;
}

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<CheckboxesInputProps, 'value' | 'onChange'> & {
  name: TName;
  control: Control<TFieldValues>;
};

const CheckboxesField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState }) => <CheckboxesInput {...field} {...fieldState} {...props} />}
    />
  );
};

export default CheckboxesField;

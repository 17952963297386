import { RouteObject, useRoutes } from 'react-router-dom';

import { lazyLoad } from '@vklink/components-shared';

import NotFoundPage from 'pages/shared/NotFoundPage';

import AccountLayout from 'pages/account/AccountLayout';

const WelcomePage = lazyLoad(() => import('pages/account/WelcomePage'));
const LoginPage = lazyLoad(() => import('pages/account/LoginPage'));
const LogoutPage = lazyLoad(() => import('pages/account/LogoutPage'));
const ForgotPasswordPage = lazyLoad(() => import('pages/account/ForgotPasswordPage'));
const ConfirmOtpPage = lazyLoad(() => import('pages/account/ConfirmOtpPage'));
const InvalidTokenCallbackPage = lazyLoad(() => import('pages/account/InvalidTokenCallbackPage'));

export const ROUTE_PATHS = {
  root: '/',
  notFound: '*',
  account: {
    root: '/account',
    login: '/account/login',
    logout: '/account/logout',
    forgotPassword: '/account/forgot-password',
    confirmOtp: '/account/confirm-otp',
  },
  expiredToken: '/connect/endsession',
};

const getRoutes = (): RouteObject[] => [
  {
    path: ROUTE_PATHS.root,
    element: <AccountLayout />,
    children: [
      {
        path: ROUTE_PATHS.account.root,
        element: <WelcomePage />,
      },
      {
        path: ROUTE_PATHS.account.login,
        element: <LoginPage />,
      },
      {
        path: ROUTE_PATHS.account.logout,
        element: <LogoutPage />,
      },
      {
        path: ROUTE_PATHS.account.forgotPassword,
        element: <ForgotPasswordPage />,
      },
      {
        path: ROUTE_PATHS.account.confirmOtp,
        element: <ConfirmOtpPage />,
      },
      {
        path: ROUTE_PATHS.expiredToken,
        element: <InvalidTokenCallbackPage />,
      },
    ],
  },
  { path: ROUTE_PATHS.notFound, element: <NotFoundPage /> },
];

const Routes = () => {
  const routes = getRoutes();

  return useRoutes(routes);
};

export default Routes;

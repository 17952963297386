import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { HtmlInput, HtmlInputProps } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = HtmlInputProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  maxLength?: number;
  imageHandler: HtmlInputProps['imageHandler'];
};

const HtmlField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error } }) => (
        <HtmlInput
          label={props.label}
          style={props.style}
          placeholder={props.placeholder}
          {...field}
          {...props}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default HtmlField;

import { useToastApi } from './createToastContext';
import { ToastSeverity, ToastVariant } from './types';

export const useToast = () => {
  const { add } = useToastApi();

  const toast = (
    title: string,
    message: string,
    severity: ToastSeverity = 'info',
    variant: ToastVariant = 'standard'
  ) =>
    add({
      id: Math.random().toString(),
      title,
      message,
      severity,
      variant,
    });

  return toast;
};

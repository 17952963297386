import { render } from 'react-dom';
import App from './App';

const rootElement = document.getElementById('root')!;

function renderApp() {
  render(<App />, rootElement);
}

renderApp();

import { useMemo } from 'react';

import { Box, Checkbox, CheckboxProps, FormControlLabel, FormLabel, Grid } from '@mui/material';

export interface GroupCheckboxesOption {
  group: string;
  label: string;
  value: string;
  title?: string;
}

export type GroupCheckboxesInputProps = Pick<
  CheckboxProps,
  'value' | 'checked' | 'readOnly' | 'disabled' | 'onChange'
> & {
  options: Array<GroupCheckboxesOption>;
  onChange: (...event: any[]) => void;
  value: any;
  label?: string | React.ReactNode;
};

const GroupCheckboxesInput = (props: GroupCheckboxesInputProps) => {
  const { value, onChange } = props;

  const groups = useMemo(() => {
    return [...new Set(props.options.map((x) => x.group))];
  }, [props.options]);

  const groupOptions = useMemo(() => {
    return groups.map((group) => {
      const options = props.options.filter((x) => x.group === group);
      return {
        name: group,
        options,
      };
    });
  }, [groups, props.options]);

  return (
    <Box className="GroupCheckboxesField-root">
      {props.label && (
        <FormLabel
          sx={{
            color: '#424242',
          }}
        >
          {props.label}
        </FormLabel>
      )}
      {groupOptions.map((group) => (
        <Grid key={group.name} container className="GroupCheckboxesField-group">
          <Grid item xs={12}>
            {group.name}
          </Grid>
          {group.options.map((option, index) => (
            <Grid item key={option.value + index} sm={12} md={6} lg={4} xl={3}>
              <FormControlLabel
                key={index}
                label={option.label}
                control={
                  <Checkbox
                    title={option.label}
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    value={option.value}
                    checked={value.includes(option.value)}
                    inputProps={{
                      readOnly: props.readOnly,
                      disabled: props.readOnly || props.disabled,
                    }}
                    onChange={(event) => {
                      const values = [...value];

                      if (event.target.checked) {
                        onChange([...values, event.target.value]);
                      } else {
                        values.splice(value.indexOf(event.target.value), 1);

                        onChange([...values]);
                      }
                    }}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

export default GroupCheckboxesInput;

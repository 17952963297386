import { useState, useCallback } from 'react';

const useAnchorElDisclosure = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useCallback((evt: any) => {
    try {
      setAnchorEl(evt.currentTarget);
    } catch (error) {}
  }, []);
  const close = useCallback(() => setAnchorEl(null), []);

  return { isOpen: Boolean(anchorEl), open, close, anchorEl };
};

export default useAnchorElDisclosure;

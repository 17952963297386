import { createContext, useContext } from 'react';

import { ToastState, ToastApi } from './types';

const ToastStateContext = createContext<ToastState | null>(null);
export const ToastStateProvider = ToastStateContext.Provider;

export const useToastState = (): ToastState => {
  const toastState = useContext(ToastStateContext);

  if (toastState == null) {
    throw new Error('Toast State cannot be null, please add a context provider');
  }

  return toastState;
};

const ToastApiContext = createContext<ToastApi | null>(null);
export const ToastApiProvider = ToastApiContext.Provider;

export const useToastApi = (): ToastApi => {
  const toastApi = useContext(ToastApiContext);

  if (toastApi == null) {
    throw new Error('Toast Api cannot be null, please add a context provider');
  }

  return toastApi;
};

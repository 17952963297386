import { useMemo, useState } from 'react';

import { ToastMessage, ToastProviderProps } from './types';
import { ToastApiProvider, ToastStateProvider } from './createToastContext';
import ToastMessageContainer from './ToastMessageContainer';

const ToastMessageProvider = (props: ToastProviderProps) => {
  const [messages, setMessages] = useState<Array<ToastMessage>>([]);

  const toastConfigProps = useMemo(
    () => ({
      position: props.position ?? 'top-right',
      duration: props.duration ?? 4000,
    }),
    [props.position, props.duration]
  );

  const add = (message: ToastMessage) => setMessages((prevMessages) => [...prevMessages, message]);

  const remove = (id: string) =>
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));

  return (
    <ToastApiProvider
      value={{
        add,
        remove,
      }}
    >
      <ToastStateProvider
        value={{
          messages,
        }}
      >
        <ToastMessageContainer {...toastConfigProps} />
        {props.children}
      </ToastStateProvider>
    </ToastApiProvider>
  );
};

export default ToastMessageProvider;

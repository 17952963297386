import { createTheme } from '@mui/material/styles';
import { defaultTheme } from '@vklink/components-shared';

const theme = createTheme(defaultTheme, {
  palette: {
    mode: 'light',
    primary: {
      main: '#263C97',
    },
    warning: {
      main: '#fcbe00',
      dark: '#f9a825',
    },
  },
});

export default theme;

import { useEffect, useState } from 'react';

import { RETURN_URL_SEARCH_PARAM, RETURN_URL_SEARCH_PARAM_LENGTH } from 'common/constants';

export const useReturnUrl = () => {
  const [returnUrl, setReturnUrl] = useState('');

  useEffect(() => {
    const returnUrlIdx = window.location.href.indexOf(RETURN_URL_SEARCH_PARAM);

    if (returnUrlIdx > 0) {
      const returnUrl = window.location.href.substring(
        returnUrlIdx + RETURN_URL_SEARCH_PARAM_LENGTH
      );

      setReturnUrl(returnUrl);
    }
  }, [window.location.search]);

  return returnUrl;
};

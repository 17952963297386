export const RETURN_URL_KEY = 'returnUrl';
export const RETURN_URL_SEARCH_PARAM = `?${RETURN_URL_KEY}=`;
export const RETURN_URL_SEARCH_PARAM_LENGTH = RETURN_URL_SEARCH_PARAM.length;

export const LOGOUT_ID_PARAM = '?logout_id=';
export const LOGOUT_ID_PARAM_LENGTH = LOGOUT_ID_PARAM.length;
export const LOGOUT_REDIRECT_URI = 'post_logout_redirect_uri';

export const CONTEXT_URL = '/connect/context';
export const LOGIN_URL = '/account/login';
export const AUTHORIZE_URL = '/connect/authorize';
export const CONSENT_URL = '/connect/consent';
export const END_SESSION_URL = '/connect/endsession';

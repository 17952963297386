import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { UploadInputProps, UploadInput } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<UploadInputProps, 'value' | 'onChange'> & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  onChange?: (value: any) => void;
};

const UploadField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error } }) => (
        <UploadInput
          {...field}
          {...props}
          helperText={props.helperText ?? error?.message}
          onChange={(files) => {
            const file = files.map((el) => el.file).filter((item) => !!item);
            field.onChange && field.onChange(files);
            props.onChange && props.onChange(file);
          }}
        />
      )}
    />
  );
};

export default UploadField;

import { forwardRef, ReactNode } from 'react';

import { FormLabel, FormHelperText, Stack, FormControl } from '@mui/material';
import { MuiColorInput, MuiColorInputProps } from 'mui-color-input';

export type ColorInputProps = MuiColorInputProps & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
};

const ColorInput = (props: ColorInputProps, ref: React.Ref<HTMLDivElement>) => {
  const { label, helperText, direction, spacing, sx, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;
  return (
    <Stack
      direction={formDirection}
      spacing={formSpacing}
      sx={{
        ...sx,
      }}
    >
      {label && (
        <FormLabel sx={{ color: '#424242' }} required={props.required}>
          {label}
        </FormLabel>
      )}
      <FormControl size="small">
        <MuiColorInput ref={ref} {...inputProps} format="hex" />
      </FormControl>
      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(ColorInput);

import { ReactNode } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import BaseInput, { BaseInputProps } from './BaseInput';

export type AutocompleteInputOption = {
  label: string;
  value: string | number;
  icon?: ReactNode;
  [key: string]: any;
};

export type AutocompleteInputProps<
  T extends string | number,
  O extends AutocompleteInputOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> &
  Pick<BaseInputProps, 'label' | 'error' | 'helperText'> & {
    selection?: boolean;
    optionLabels: Array<O>;
  };
const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: string | number,
  optionLabels: Array<AutocompleteInputOption>,
  state: AutocompleteRenderOptionState,
  selection?: boolean
) => {
  const optionLabel = optionLabels.find((ol) => ol.value === option);
  const label = optionLabel?.label ?? '';
  const matches = match(label, state.inputValue);
  const parts = parse(label, matches);

  const PartElements = parts.map((part, index) => (
    <Box
      key={index}
      component="span"
      sx={{
        fontWeight: (theme) =>
          part.highlight ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
      }}
    >
      {part.text}
    </Box>
  ));

  return (
    <MenuItem {...props}>
      {selection ? (
        <FormControlLabel
          control={<Checkbox icon={optionLabel?.icon} checked={state.selected} />}
          label={PartElements}
        />
      ) : (
        <ListItemText>{PartElements}</ListItemText>
      )}
    </MenuItem>
  );
};

const AutocompleteInput = <
  T extends string | number,
  O extends AutocompleteInputOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteInputProps<T, O, Multiple, DisableClearable, FreeSolo>
) => {
  const { label, helperText, error, optionLabels, placeholder, ...restProps } = props;

  return (
    <Autocomplete
      {...restProps}
      renderOption={(renderOptionProps, option, state) =>
        renderOption(
          renderOptionProps,
          option,
          optionLabels,
          state,
          props.selection && props.multiple
        )
      }
      isOptionEqualToValue={(option, value) => {
        if (!value) return true;

        return option === value;
      }}
      getOptionDisabled={(option) => props.optionLabels.find((ol) => ol.value === option)?.disabled}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderInput={({ InputLabelProps, InputProps, ...restRenderInput }) => {
        return (
          <BaseInput
            {...restRenderInput}
            {...InputProps}
            label={label}
            helperText={helperText}
            error={error}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default AutocompleteInput;

import { ReactNode } from 'react';

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  SwitchProps,
} from '@mui/material';

export type BaseSwitchProps = SwitchProps & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  subLabel?: ReactNode;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
};

const BaseSwitch = (props: BaseSwitchProps) => {
  const {
    labelPlacement,
    subLabel,
    label,
    helperText,
    direction,
    spacing,
    sx,
    required,
    ...inputProps
  } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack
      direction={formDirection}
      spacing={formSpacing}
      sx={{
        ...sx,
      }}
    >
      {label && (
        <FormLabel
          sx={{
            color: '#424242',
          }}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <FormControlLabel
        control={<Switch {...inputProps} />}
        label={subLabel}
        labelPlacement={labelPlacement ?? 'end'}
      />
      {helperText && <FormHelperText sx={{ color: 'gray' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default BaseSwitch;

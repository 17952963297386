import { yup } from '@vklink/components-form';
import { useCallback } from 'react';
import { FieldError } from 'react-hook-form';

const parseErrorSchema = (error: yup.ValidationError) => {
  return error.inner.reduce<Record<string, FieldError>>((previous, current) => {
    return {
      ...previous,
      [current.path!]: {
        type: current.type ?? 'validation',
        message: current.message,
      },
    };
  }, {});
};
const YupResolver = (validationSchema: yup.AnyObjectSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (error: any) {
        return {
          values: {},
          errors: parseErrorSchema(error),
        };
      }
    },
    [validationSchema]
  );

export default YupResolver;

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

const parse = (queryString: string) => {
  return qs.parse(queryString, {
    allowDots: true,
    parseArrays: true,
    decoder(str, _, charset) {
      const strWithoutPlus = str.replace(/\+/g, ' ');
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      } as any;
      if (str in keywords) {
        return keywords[str];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  });
};

const useQueryParams = <TParams extends Record<string, any>>(): [
  TParams,
  (params: Partial<TParams>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParamsInternal] = useState(() => {
    const params = parse(searchParams.toString());

    return params as TParams;
  });

  useEffect(() => {
    setSearchParams(
      qs.stringify(queryParams, {
        allowDots: true,
        skipNulls: true,
      })
    );
  }, [queryParams]);

  useEffect(() => {
    const parsedSearchParams = parse(searchParams.toString());
    const currentSearch = qs.stringify(parsedSearchParams, {
      allowDots: true,
      skipNulls: true,
    });
    const lastSearch = qs.stringify(queryParams, {
      allowDots: true,
      skipNulls: true,
    });

    if (currentSearch !== lastSearch) {
      setQueryParamsInternal(parsedSearchParams as TParams);
    }
  }, [searchParams]);

  const setQueryParams = (params: Partial<TParams>) => {
    setQueryParamsInternal((queryParams) => {
      return { ...queryParams, ...params };
    });
  };

  return [queryParams, setQueryParams];
};

export default useQueryParams;

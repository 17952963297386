import { forwardRef } from 'react';
import BaseSelect, { BaseSelectProps } from './BaseSelect';

type Props = Omit<BaseSelectProps, 'endAdornment' | 'type'>;

const SelectInput = (props: Props, ref: any) => {
  return <BaseSelect ref={ref} {...props} />;
};

export default forwardRef(SelectInput);

import { useState, MouseEvent, forwardRef } from 'react';

import { IconButton } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

import BaseInput, { BaseInputProps } from './BaseInput';

type Props = Omit<BaseInputProps, 'endAdornment' | 'type'>;

const PasswordInput = (props: Props, ref: React.Ref<HTMLDivElement>) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleVisiblePassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setShowPassword(!showPassword);
  };

  return (
    <BaseInput
      {...props}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton onClick={handleToggleVisiblePassword} size="small">
          {showPassword ? (
            <VisibilityOutlined fontSize="small" />
          ) : (
            <VisibilityOffOutlined fontSize="small" />
          )}
        </IconButton>
      }
    />
  );
};

export default forwardRef(PasswordInput);

import {
  Box,
  Breadcrumbs as BaseBreadcrumbs,
  Link,
  Typography,
  BreadcrumbsProps,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

export type BreadcrumbItemProps = {
  title: string;
  path?: string;
  icon?: SvgIconComponent;
};

type Props = {
  items: Array<BreadcrumbItemProps>;
  homeText?: string;
  homePath?: string;

  sx?: BreadcrumbsProps['sx'];
};

const Breadcrumbs = (props: Props) => {
  return (
    <BaseBreadcrumbs
      aria-label="breadcrumb"
      // separator={<NavigateNextOutlined fontSize="small" />}
      sx={props.sx}
    >
      <Box className="breadcrumbs-item">
        <Link
          sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          color="inherit"
          href={props.homePath ? props.homePath : '/'}
        >
          {props.homeText || 'Dashboard'}
        </Link>
      </Box>
      {props.items.map((item, index) => {
        const isLast = index === props.items.length - 1;
        const IconComponent = item.icon as SvgIconComponent;
        const className = `breadcrumbs-item ${isLast ? 'last' : ''}`;

        const Title = (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: (theme) => (isLast ? theme.palette.common.black : undefined),
              fontWeight: isLast ? '500' : undefined,
            }}
          >
            {IconComponent && <IconComponent sx={{ mr: 1 }} />}
            {item.title}
          </Typography>
        );

        if (!item.path)
          return (
            <Box key={index} className={className}>
              {Title}
            </Box>
          );

        return (
          <Box key={index} className={className}>
            <Link
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
              color="inherit"
              href={item.path}
            >
              {Title}
            </Link>
          </Box>
        );
      })}
    </BaseBreadcrumbs>
  );
};

export default Breadcrumbs;

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { BaseRangePicker, BaseRangePickerProps } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseRangePickerProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
};

const DateRangeField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <BaseRangePicker
          placeholder={props.placeholder}
          required={props.required}
          error={props.error ?? invalid}
          label={props.label}
          disabled={props.disabled}
          readOnly={props.readOnly}
          {...field}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default DateRangeField;

import React, { useState } from 'react';

import { Theme, CSSObject } from '@mui/material/styles';
import { Drawer, DrawerProps } from '@mui/material';

import { useDisclosure } from '@vklink/components-shared';
import { makeStyles } from 'styles';

type Props = {
  width: number;
  children: (props: {
    isOpen: boolean;
    isExpand: boolean;
    onToggleClick: () => void;
  }) => React.ReactNode;
  sx?: DrawerProps['sx'];
};

const useStyles = makeStyles<{ open: boolean; expand: boolean; width: number }>()(
  (theme, { open, expand, width }) => ({
    sideNav: {
      width,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      position: !expand ? 'relative' : open ? 'relative' : 'absolute',
      // Workaround for issue Property '@font-face' is incompatible with index signature,
      // see https://github.com/garronej/tss-react/issues/50
      ...(theme.mixins.toolbar as CSSObject),
      ...(open && {
        ...sideNavOpenedMixin(theme, width),
        '& .MuiDrawer-paper': {
          ...sideNavOpenedMixin(theme, width),
          borderRight: `1px dashed ${theme.palette.divider}`,
        },
      }),
      ...(!open && {
        ...sideNavClosedMixin(theme, expand, width),
        '& .MuiDrawer-paper': {
          ...sideNavClosedMixin(theme, expand, width),
          borderRight: `1px dashed ${theme.palette.divider}`,
        },
      }),
    },
  })
);

const CollapsibleDrawer = (props: Props) => {
  const { isOpen, toggle } = useDisclosure(true);
  const [expand, setExpand] = useState(isOpen);
  const { classes } = useStyles({ open: isOpen, expand, width: props.width });

  const onMouseEnter = () => {
    if (isOpen) {
      return;
    }

    setExpand(true);
  };

  const onMouseLeave = () => {
    if (isOpen) {
      return;
    }

    setExpand(false);
  };

  const onToggleClick = () => {
    toggle();
  };

  return (
    <Drawer
      sx={props.sx}
      className={classes.sideNav}
      open={isOpen}
      variant="permanent"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.children({ isOpen, onToggleClick, isExpand: expand })}
    </Drawer>
  );
};

export default CollapsibleDrawer;

const sideNavOpenedMixin = (theme: Theme, width: number): CSSObject => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const sideNavClosedMixin = (theme: Theme, expand: boolean, width: number): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: expand ? width : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: expand ? width : `calc(${theme.spacing(8)} + 1px)`,
  },
});

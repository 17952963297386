import { forwardRef } from 'react';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { FormLabel, Stack, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BaseInput from './BaseInput';

export type BaseDateTimeProps = Omit<DateTimePickerProps, 'renderInput'> & {
  label?: string;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  t?: (key: string, context?: Record<string, unknown>) => string;
};

function BaseDateTime(props: BaseDateTimeProps, ref: any) {
  const { t, label, direction, spacing, placeholder, value, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {label && <FormLabel sx={{ color: '#424242' }}>{t ? t(label) : label}</FormLabel>}
        <DateTimePicker
          ref={ref}
          value={value != '' ? value : null}
          label={placeholder}
          inputFormat="yyyy-MM-dd HH:mm:ss"
          {...inputProps}
          renderInput={({
            InputLabelProps,
            InputProps,
            inputRef,
            onKeyDown,
            onKeyUp,
            margin,
            ...restInputProps
          }) => (
            <BaseInput
              {...restInputProps}
              {...InputProps}
              ref={inputRef}
              required={props.required}
              error={props.error}
              helperText={props.helperText}
            />
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default forwardRef(BaseDateTime);

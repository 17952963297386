import '@fontsource/roboto';

import { StrictMode, Suspense } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { I18nProvider } from '@vklink/libs-i18n';
import { TopLoading } from '@vklink/components-shared';

import i18n from 'i18n';

import theme from './themes';
import Routes from 'pages/shared/Routes';
import { ToastMessageProvider } from '@vklink/components-toast-message';

const App = () => {
  return (
    <StrictMode>
      <I18nProvider i18n={i18n}>
        <ToastMessageProvider position="top-right">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Suspense fallback={<TopLoading />}>
                <Routes />
              </Suspense>
            </Router>
          </ThemeProvider>
        </ToastMessageProvider>
      </I18nProvider>
    </StrictMode>
  );
};

export default App;

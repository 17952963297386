import { ChangeEvent, forwardRef } from 'react';

import { BaseSelectProps } from './BaseSelect';
import { Box, Button, FormHelperText, FormLabel, Stack } from '@mui/material';

const SelectVariationInput = (props: BaseSelectProps, ref: React.Ref<HTMLDivElement>) => {
  const { options, value, helperText, label } = props;

  const onChange = (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange && props.onChange(value);
  };

  return (
    <Stack ref={ref} direction="row">
      {label && (
        <FormLabel sx={{ color: '#424242', minWidth: 100 }} required={props.required}>
          {label}
        </FormLabel>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
        gap={2}
        rowGap={1}
      >
        {options.map((el) => (
          <Button
            variant={value === el.value ? 'contained' : 'outlined'}
            key={el.value}
            onClick={() => onChange(el.value)}
            disabled={el.disabled}
            size="small"
            sx={{ height: 28 }}
          >
            {el.label}
          </Button>
        ))}
      </Box>

      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(SelectVariationInput);

import { FiberManualRecord } from '@mui/icons-material';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import dayjs from 'dayjs';

type Props = {
  trackAndTrace: string;
};

const TrackAndTrace = (props: Props) => {
  const stepsTrackAndTrace = JSON.parse(props.trackAndTrace);

  return (
    <Stepper
      activeStep={1}
      orientation="vertical"
      sx={{
        '& .MuiStepLabel-root': {
          p: 0,
        },
        '& .MuiStepConnector-root': {
          my: -1,
        },
        '& .MuiSvgIcon-root': {
          height: 16,
          zIndex: 1,
        },
        '& .MuiStepLabel-iconContainer': {
          color: 'grey.400',
        },
        '& .Mui-completed .MuiStepLabel-iconContainer': {
          color: 'success.main',
        },
        '& .Mui-completed .Mui-completed': {
          color: 'success.main',
        },
      }}
    >
      {stepsTrackAndTrace.map((step: any, index: number) => (
        <Step key={index}>
          <StepLabel StepIconComponent={FiberManualRecord}>
            <Typography variant="body2">
              {step?.Date && dayjs(step.Date).format('YYYY-MM-DD HH:mm:ss')}
              <Typography variant="body2" component="span" ml={1}>
                {step?.Status}
              </Typography>
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TrackAndTrace;

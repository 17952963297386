import { createRef, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';

import {
  Stack,
  TextField,
  TextFieldProps,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

export type OtpInputProps = TextFieldProps & {
  label?: ReactNode;
  codeLength: number;
  onChange: (otp: string) => void;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
};

const OtpInput = (props: OtpInputProps, ref: React.Ref<HTMLDivElement>) => {
  const { label, helperText, direction, spacing, sx, ...inputProps } = props;

  const [otp, setOtp] = useState<string[]>([]);
  const inputRefs = useMemo(
    () =>
      Array(props.codeLength)
        .fill(0)
        .map(() => createRef<any>()),
    [props.codeLength]
  );

  const formDirection = direction === 'vertical' ? 'column' : 'row';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  const onChangeInput = (val: string, idx: number) => {
    const tempOtp = [...otp];
    tempOtp[idx] = val;
    setOtp(tempOtp);
    if (idx < inputRefs.length - 1 && val !== '') {
      inputRefs[idx + 1].current?.focus();
    }
  };

  const onPasteOtp = (otp: string) => {
    if (!!inputRefs.length) {
      setOtp(otp.split(''));
      props.onChange(otp);
    }
  };

  const focusPrevious = (key: string, idx: number, currentVal: string) => {
    if (key === 'Backspace' && idx !== 0 && !currentVal) {
      inputRefs[idx - 1].current?.focus();
    }
  };

  useEffect(() => {
    props.onChange(otp.join(''));
  }, [otp]);

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  return (
    <Stack
      className="otp-input"
      justifyContent="center"
      sx={{
        width: '100%',
        ...sx,
      }}
      ref={ref}
    >
      {label && (
        <FormLabel sx={{ color: '#424242' }} required={props.required}>
          {label}
        </FormLabel>
      )}
      <FormControl size="small" sx={{ flexDirection: formDirection, gap: formSpacing }}>
        {Array(props.codeLength)
          .fill(0)
          .map((_, idx) => {
            return (
              <TextField
                onPaste={(evt) => {
                  const data = evt.clipboardData.getData('text');
                  const isValidOtp = new RegExp('\\d{' + props.codeLength + '}').test(data);

                  if (isValidOtp) {
                    onPasteOtp(data);
                  }
                }}
                key={idx}
                variant="standard"
                {...inputProps}
                value={otp[idx]}
                onChange={(evt) => onChangeInput(evt.target.value, idx)}
                onKeyUp={(evt) => focusPrevious(evt.key, idx, otp[idx])}
                inputRef={inputRefs[idx]}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: 'center',
                  },
                }}
              />
            );
          })}
      </FormControl>
      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(OtpInput);

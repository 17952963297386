import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { BaseDate } from '@vklink/components-shared';
import { BaseDateProps } from '@vklink/components-shared/src/input/BaseDate';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<BaseDateProps, 'onChange' | 'value'> & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
};

const DateField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <BaseDate
          placeholder={props.placeholder}
          required={props.required}
          error={props.error ?? invalid}
          label={props.label}
          disabled={props.disabled}
          readOnly={props.readOnly}
          clearable={props.clearable}
          maxDate={props.maxDate}
          minDate={props.minDate}
          {...field}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default DateField;

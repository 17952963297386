import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { PasswordInput, BaseInputProps } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseInputProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  maxLength?: number;
  onChange?: (evt: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const PasswordField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <PasswordInput
          type={props.type}
          required={props.required}
          label={props.label}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          sx={props.sx}
          size={props.size}
          placeholder={props.placeholder}
          {...field}
          {...props}
          inputProps={{ maxLength: props.maxLength }}
          onChange={(e) => {
            field.onChange(e.target.value);
            props.onChange && props.onChange(e.target.value);
          }}
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
          error={props.error ?? invalid}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default PasswordField;

import { createI18n } from '@vklink/libs-i18n';

import common from './locales/en/common.json';

export const fallbackLng = 'en';
export const defaultNS = 'common';
export const supportedLngs = ['en', 'vi'];
export const resources = {
  en: {
    common,
  },
  vi: {
    common: require('./locales/vi/common.json'),
  },
};

const i18n = createI18n(resources, fallbackLng, supportedLngs, defaultNS);

export default i18n;

import { Typography } from '@mui/material';

type Props = {
  count: number;
  noun: string;
  suffix?: string;
  language?: string;
};

const Pluralize = (props: Props) => {
  const pluralize = (count: number, noun: string, suffix = 's') => {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
  };

  const pluralizeInVietnamese = (count: number, noun: string) => {
    return `${count} ${noun}`;
  };

  return (
    <Typography variant="inherit" component="span">
      {props.language === 'en'
        ? pluralize(props.count, props.noun, props.suffix)
        : pluralizeInVietnamese(props.count, props.noun)}
    </Typography>
  );
};

export default Pluralize;

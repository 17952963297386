import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { BaseNumericInput, BaseNumericInputProps } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseNumericInputProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  maxLength?: number;
};

const NumericField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <BaseNumericInput
          required={props.required}
          label={props.label}
          disabled={props.disabled}
          sx={props.sx}
          {...field}
          {...props}
          value={field.value as number}
          error={props.error ?? invalid}
          helperText={error?.message ?? props.helperText}
        />
      )}
    />
  );
};

export default NumericField;

import { memo } from 'react';
import { Box, LinearProgress } from '@mui/material';

const Loading = memo(() => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 999999,
        width: '100%',
      }}
    >
      <LinearProgress color="primary" />
    </Box>
  );
});

Loading.displayName = 'Loading';

export default Loading;

import { Chip } from '@mui/material';

type Props = {
  status: 'done' | 'partial' | 'in process' | 'abnormal';
  label?: string;
  maxWidth?: number;
};

const StatusColorMap = {
  done: 'success',
  partial: 'error',
  'in process': 'info',
  abnormal: 'warning',
};

const StatusChip = (props: Props) => {
  return (
    <Chip
      sx={{
        fontWeight: 'bold',
        width: '100%',
        maxWidth: props.maxWidth || 100,
        textTransform: 'capitalize',
      }}
      size="small"
      label={props.label || props.status}
      color={StatusColorMap[props.status] as any}
      variant="filled"
    />
  );
};

export default StatusChip;

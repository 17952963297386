import { forwardRef, ReactNode } from 'react';
import ImageUploading, { ImageUploadingPropsType } from 'react-images-uploading';

import {
  FormLabel,
  Stack,
  FormControl,
  Box,
  Button,
  IconButton,
  Tooltip,
  SxProps,
  Theme,
} from '@mui/material';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';

export type UploadInputProps = ImageUploadingPropsType & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  uploadText?: string;
  removeText?: string;
};

const UploadInput = (props: UploadInputProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    helperText,
    label,
    direction,
    spacing,
    maxNumber,
    disabled,
    multiple,
    sx,
    uploadText,
    removeText,
    ...inputProps
  } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing} ref={ref} sx={sx}>
      {label && <FormLabel sx={{ color: '#424242' }}>{label}</FormLabel>}
      <FormControl
        size="small"
        sx={{
          backgroundColor: '#F3F5F9',
          textAlign: 'center',
          border: '1px dashed',
          borderColor: helperText ? 'error.main' : 'grey.500',
          borderRadius: 2,
          py: 2,
          minHeight: 150,
          justifyContent: 'center',
        }}
      >
        <ImageUploading multiple={multiple} {...inputProps} maxNumber={maxNumber ?? 10}>
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <Box>
              <Button
                sx={{
                  color: isDragging ? 'error.main' : undefined,
                }}
                onClick={onImageUpload}
                {...dragProps}
                disabled={disabled}
              >
                {uploadText ?? 'Click or Drop here'}
              </Button>
              {multiple && (
                <Button onClick={onImageRemoveAll} disabled={disabled}>
                  {removeText ?? 'Remove all images'}
                </Button>
              )}
              <Stack
                direction="row"
                sx={{
                  overflowX: 'auto',
                  overflowY: 'visible',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                spacing={1}
              >
                {imageList.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      overflow: 'visible',

                      ':hover': {
                        '& .actions': {
                          visibility: 'visible',
                        },
                        '& img': {
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={image.dataURL}
                      alt="upload-img"
                      width={100}
                      height={100}
                      sx={{
                        objectFit: 'contain',
                        p: 0.5,
                        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                      }}
                    />

                    <Stack
                      className="actions"
                      direction="row"
                      sx={{
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: 0,
                        left: 0,
                        visibility: 'hidden',
                      }}
                      spacing={1}
                    >
                      <Tooltip title="Update">
                        <IconButton
                          sx={{ color: 'common.black' }}
                          onClick={() => onImageUpdate(index)}
                          disabled={disabled}
                        >
                          <EditOutlined sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove">
                        <IconButton
                          color="error"
                          onClick={() => onImageRemove(index)}
                          disabled={disabled}
                        >
                          <DeleteOutlineOutlined sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </ImageUploading>
      </FormControl>
    </Stack>
  );
};

export default forwardRef(UploadInput);

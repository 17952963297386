import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { BaseInputProps, SelectVariationInput } from '@vklink/components-shared';

type Props<
  TOption extends Record<string, any> = SelectOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseInputProps & {
  name: TName;
  options: TOption[];
  control: Control<TFieldValues>;
};

const SelectVariationField = <
  TOption extends SelectOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TOption, TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <SelectVariationInput
          options={props.options}
          label={props.label}
          {...field}
          error={props.error ?? invalid}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default SelectVariationField;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef } from 'react';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { FormLabel, Stack, FormControl } from '@mui/material';
import BaseInput from './BaseInput';

export type BaseDateProps = Omit<DatePickerProps, 'renderInput'> & {
  label?: string;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  t?: (key: string, context?: Record<string, unknown>) => string;
};

const BaseDate = (props: BaseDateProps, ref: any) => {
  const { t, label, direction, spacing, placeholder, value, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing}>
      <FormControl size="small" sx={{ minWidth: 120 }}>
        {label && <FormLabel sx={{ color: '#424242', pb: 1 }}>{t ? t(label) : label}</FormLabel>}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            ref={ref}
            inputFormat="yyyy-MM-dd"
            mask="____-__-__"
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={value != '' ? value : null}
            {...inputProps}
            renderInput={({
              inputProps,
              InputProps,
              inputRef,
              onKeyDown,
              onKeyUp,
              margin,
              ...restInputProps
            }) => (
              <BaseInput
                {...restInputProps}
                {...InputProps}
                inputProps={{ ...inputProps, placeholder: placeholder ?? 'Year-Month-Day' }}
                ref={inputRef}
                required={props.required}
                error={props.error}
                helperText={props.helperText}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </Stack>
  );
};

export default forwardRef(BaseDate);

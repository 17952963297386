import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { BaseRadioGroupProps, RadioGroupInput } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseRadioGroupProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  onChange?: (evt: string) => void;
};

const RadioGroupField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error } }) => (
        <RadioGroupInput
          label={props.label}
          sx={props.sx}
          placeholder={props.placeholder}
          options={props.options}
          disabled={props.disabled}
          row={props.row}
          t={props.t}
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);
            props.onChange && props.onChange(e.target.value);
          }}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default RadioGroupField;

import { Chip } from '@mui/material';

type Props = {
  status: 'INTRANSIT' | 'COMPLETED_ABNORMAL' | 'COMPLETED' | 'CANCELLED' | 'INPUT_PARTIAL';
  label?: string;
  maxWidth?: number;
};

const StatusColorMap = {
  INTRANSIT: 'info',
  COMPLETED_ABNORMAL: 'warning',
  INPUT_PARTIAL: 'secondary',
  COMPLETED: 'success',
  CANCELLED: 'error',
  INITIAL: 'primary',
};

const ImportStockStatusChip = (props: Props) => {
  return (
    <Chip
      sx={{
        fontWeight: 'bold',
        width: '100%',
        textTransform: 'capitalize',
        maxWidth: props.maxWidth || 100,
      }}
      size="small"
      label={props.label || props.status}
      color={StatusColorMap[props.status] as any}
      variant="filled"
    />
  );
};

export default ImportStockStatusChip;

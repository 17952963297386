import { Chip } from '@mui/material';

type Props = {
  status: 'INIT' | 'IN_PROCESS' | 'APPROVED' | 'REJECTED' | 'CANCELLED';
  label?: string;
  maxWidth?: number;
};

const AdjustmentColorMap = {
  INIT: 'info',
  IN_PROCESS: 'primary',
  APPROVED: 'success',
  REJECTED: 'warning',
  CANCELLED: 'error',
};

const AdjustmentStatusChip = (props: Props) => {
  return (
    <Chip
      sx={{
        fontWeight: 'bold',
        width: '100%',
        maxWidth: props.maxWidth || 100,
        textTransform: 'capitalize',
      }}
      size="small"
      label={props.label || props.status}
      color={AdjustmentColorMap[props.status] as any}
      variant="filled"
    />
  );
};

export default AdjustmentStatusChip;

import { Chip } from '@mui/material';

type Props = {
  status:
    | 'WAITING_ORDER'
    | 'CONFIRMED_ORDER'
    | 'PICKED_UP_ORDER'
    | 'EXPORTED_ORDER'
    | 'RECEIVED_ORDER'
    | 'FULL_RETURN_ORDER'
    | 'PARTIAL_RETURN_ORDER'
    | 'REPLACEMENT_ORDER'
    | 'CANCEL_ORDER';
  label?: string | number;
  maxWidth?: number;
};

const StatusColorMap = {
  WAITING_ORDER: 'primary',
  CONFIRMED_ORDER: 'info',
  PICKED_UP_ORDER: 'primary',
  EXPORTED_ORDER: 'success',
  RECEIVED_ORDER: 'success',
  CONFIRMED: 'success',
  FULL_RETURN_ORDER: 'warning',
  PARTIAL_RETURN_ORDER: 'warning',
  REPLACEMENT_ORDER: 'warning',
  CANCEL_ORDER: 'warning',
};

function getOrderStatusLable(status: string) {
  switch (status) {
    case 'WAITING_ORDER':
      return 'Waiting Confirmation';
    case 'CONFIRMED_ORDER':
      return 'Confirmed';
    case 'PICKED_UP_ORDER':
      return 'Picked';
    case 'EXPORTED_ORDER':
      return 'Shipping';
    case 'RECEIVED_ORDER':
      return 'Deliveried';
    case 'FULL_RETURN_ORDER':
      return 'Full Return';
    case 'PARTIAL_RETURN_ORDER':
      return 'Partial Return';
    case 'REPLACEMENT_ORDER':
      return 'Replacement';
    case 'CANCEL_ORDER':
      return 'Cancel';
    case 'CONFIRMED':
      return 'Confirmed';
    default:
      return 'Waiting Confirmation';
  }
}

const OrderStatusChip = (props: Props) => {
  return (
    <Chip
      sx={{
        fontWeight: 'bold',
        width: '100%',
        maxWidth: props.maxWidth || 100,
        textTransform: 'capitalize',
      }}
      size="small"
      label={props.label || getOrderStatusLable(props.status)}
      color={StatusColorMap[props.status] as any}
      variant="filled"
    />
  );
};

export default OrderStatusChip;

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { BaseSelectProps, SelectInput } from '@vklink/components-shared';

type Props<
  TOption extends Record<string, any> = SelectOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = BaseSelectProps & {
  name: TName;
  options: TOption[];
  control: Control<TFieldValues>;
  readOnly?: boolean;
  multiple?: boolean;
  onChange?: (evt: unknown) => void;
};

const SelectField = <
  TOption extends SelectOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TOption, TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <SelectInput
          required={props.required}
          label={props.label}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          sx={props.sx}
          size={props.size}
          placeholder={props.placeholder}
          options={props.options}
          multiple={props.multiple}
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);
            props.onChange && props.onChange(e.target.value);
          }}
          error={props.error ?? invalid}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default SelectField;

import { useEffect, useState } from 'react';
import qs from 'qs';
import { AUTHORIZE_URL, RETURN_URL_KEY } from 'common/constants';
import { useSearchParams } from 'react-router-dom';

export const useOriginRedirectUrl = () => {
  const [originRedirectUrl, setOriginRedirectUrl] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const returnUrl = searchParams.get(RETURN_URL_KEY);

    if (!!returnUrl) {
      const returnUrlParams = qs.parse(returnUrl.replace(AUTHORIZE_URL, ''));
      const redirectUri = returnUrlParams['redirect_uri'] as string;

      if (!!redirectUri) {
        setOriginRedirectUrl(new URL(redirectUri).origin);
      }
    }
  }, [searchParams]);

  return originRedirectUrl;
};

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { RatingInputProps, RatingInput } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = RatingInputProps & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
  onChange?: (evt: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const RatingField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <RatingInput
          ref={ref}
          required={props.required}
          label={props.label}
          disabled={props.disabled}
          sx={props.sx}
          size={props.size}
          value={Number(value)}
          onChange={(e) => {
            onChange(e);
          }}
          {...props}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default RatingField;

import { Alert, AlertTitle, SxProps, Theme } from '@mui/material';
import { useToastApi } from 'createToastContext';
import { useEffect } from 'react';

import { ToastMessage as ToastMessageType } from './types';

type Props = ToastMessageType & {
  duration: number;
  sx?: SxProps<Theme>;
};

const ToastMessage = (props: Props) => {
  const { remove } = useToastApi();

  useEffect(() => {
    setTimeout(() => remove(props.id), props.duration);
  }, []);

  return (
    <Alert
      sx={props.sx}
      severity={props.severity}
      variant={props.variant ?? 'standard'}
      onClose={() => remove(props.id)}
    >
      <AlertTitle>{props.title}</AlertTitle>
      {props.message}
    </Alert>
  );
};

export default ToastMessage;

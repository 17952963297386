import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { GroupCheckboxesInput, GroupCheckboxesInputProps } from '@vklink/components-shared';

export interface GroupCheckboxesOption {
  group: string;
  label: string;
  value: string;
  title?: string;
}

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<GroupCheckboxesInputProps, 'onChange' | 'value'> & {
  name: TName;
  control: Control<TFieldValues>;
};

const GroupCheckboxesField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => <GroupCheckboxesInput {...props} {...field} />}
    />
  );
};

export default GroupCheckboxesField;

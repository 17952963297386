import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  SvgIconComponent,
  ArrowRightAltOutlined,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Tooltip,
  Collapse,
  IconButton,
} from '@mui/material';

export enum MenuItemType {
  Link = 'LINK',
  Label = 'LABEL',
  Divider = 'DIVIDER',
}

export type MenuItem = {
  id: string;
  label: string;
  type: MenuItemType;
  href?: string;
  icon?: SvgIconComponent | any;
  childs?: Array<MenuItem>;
};

type MultipleLevelsMenuProps = {
  items: Array<MenuItem>;
  isDrawerOpen: boolean;
};

const ItemLabel = (
  props: Pick<MenuItem, 'type' | 'href' | 'label' | 'icon'> & {
    hasChild?: boolean;
    isDrawerOpen: boolean;
    level: number;
    renderChildren?: (childrenProps: { isCollapse: boolean }) => React.ReactNode;
  }
) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const toggleCollapseChildren = () => {
    if (props.hasChild) {
      setIsCollapse(!isCollapse);
    }
  };

  const Label =
    props.type === MenuItemType.Link ? (
      <ListItemButton
        onClick={toggleCollapseChildren}
        disableRipple
        dense
        sx={{
          mb: 1,
          justifyContent: props.isDrawerOpen ? 'initial' : 'center',
          pl: props.isDrawerOpen ? props.level * 3 : undefined,
          display: !props.isDrawerOpen && props.level > 1 ? 'none' : undefined,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.isDrawerOpen ? 3 : 'auto',
            justifyContent: 'center',
            color: (theme) => theme.palette.common.white,
          }}
        >
          <Tooltip title={props.label} placement="right">
            {props.icon || <ArrowRightAltOutlined />}
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
              variant="body1"
            >
              {props.label}
            </Typography>
          }
          sx={{ opacity: props.isDrawerOpen ? 1 : 0, maxWidth: props.isDrawerOpen ? 'unset' : 0 }}
        />

        {props.isDrawerOpen && props.hasChild && (
          <IconButton sx={{ color: (theme) => theme.palette.common.white }}>
            {isCollapse ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItemButton>
    ) : (
      props.isDrawerOpen && (
        <Typography
          variant="h6"
          component="div"
          sx={{
            m: 2,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: (theme) => theme.palette.common.white,
          }}
        >
          {props.label}
        </Typography>
      )
    );

  return (
    <>
      {props.href ? (
        <NavLink
          style={{
            textDecoration: 'none',
            backgroundColor: 'transparent',
          }}
          end
          to={props.href}
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          {Label}
        </NavLink>
      ) : (
        Label
      )}
      {props.renderChildren &&
        props.renderChildren({
          isCollapse,
        })}
    </>
  );
};

const renderMenuItem = (item: MenuItem, isDrawerOpen: boolean, level: number) => {
  const { childs, ...labelProps } = item;
  const hasChild = Array.isArray(childs);

  return (
    <ItemLabel
      {...labelProps}
      hasChild={hasChild}
      isDrawerOpen={isDrawerOpen}
      level={level}
      renderChildren={({ isCollapse }) => {
        return (
          <>
            {hasChild ? (
              <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {childs!.map((childItem, idx) => (
                    <Fragment key={idx}>
                      {renderMenuItem(childItem, isDrawerOpen, level + 1)}
                    </Fragment>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </>
        );
      }}
    />
  );
};

const MultipleLevelsMenu = (props: MultipleLevelsMenuProps) => {
  return (
    <List
      disablePadding
      sx={{
        '& .active': {
          '& .MuiListItemButton-root': {
            backgroundColor: (theme) => theme.palette.primary.light,
          },
        },
        '& .inactive': {
          '& .MuiTypography-root': {
            color: (theme) => theme.palette.common.white,
          },
        },
      }}
    >
      {props.items.map((item, idx) => (
        <Fragment key={idx}>{renderMenuItem(item, props.isDrawerOpen, 1)}</Fragment>
      ))}
    </List>
  );
};

export default MultipleLevelsMenu;

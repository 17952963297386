import { forwardRef } from 'react';
import {
  Stack,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from '@mui/material';

export type BaseCheckboxProps = CheckboxProps & {
  label?: string;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  t?: (key: string, context?: Record<string, unknown>) => string;
};

const BaseCheckBox = (props: BaseCheckboxProps, ref: any) => {
  const { t, label, direction, spacing, sx, value, helperText, ...checkboxProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing} sx={{ width: '100%', ...sx }}>
      <FormControlLabel
        control={<Checkbox ref={ref} {...checkboxProps} checked={Boolean(value)} />}
        label={label && <FormLabel>{t ? t(label) : label}</FormLabel>}
      />
      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(BaseCheckBox);

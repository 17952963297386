import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { OtpInput, OtpInputProps } from '@vklink/components-shared';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<OtpInputProps, 'onChange'> & {
  name: TName;
  control: Control<TFieldValues>;
  readOnly?: boolean;
};

const OtpField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: Props<TFieldValues, TName>
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { invalid, error } }) => (
        <OtpInput
          type={props.type}
          required={props.required}
          label={props.label}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          sx={props.sx}
          size={props.size}
          placeholder={props.placeholder}
          {...field}
          {...props}
          onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
          error={props.error ?? invalid}
          helperText={props.helperText ?? error?.message}
        />
      )}
    />
  );
};

export default OtpField;

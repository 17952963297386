import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Theme, ThemeProvider as BaseThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export const styleCache = createCache({
  key: 'vklinkeshop',
  prepend: true,
});

type ThemeProviderProps = {
  theme: Theme;
  children: React.ReactNode;
};

const ThemeProvider = (props: ThemeProviderProps) => {
  return (
    <CacheProvider value={styleCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BaseThemeProvider theme={props.theme}>
          <CssBaseline />
          {props.children}
        </BaseThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default ThemeProvider;

import { forwardRef } from 'react';

import {
  FormLabel,
  FormHelperText,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  RadioGroupProps,
} from '@mui/material';

export type BaseRadioGroupProps = RadioGroupProps & {
  options: SelectOption[];
  label?: string;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  disabled?: boolean;
  t?: (key: string, context?: Record<string, unknown>) => string;
};

const BaseRadioGroup = (props: BaseRadioGroupProps, ref: React.Ref<HTMLDivElement>) => {
  const { t, label, helperText, direction, spacing, sx, ...radioProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing} sx={{ width: '100%', ...sx }} ref={ref}>
      {label && <FormLabel sx={{ color: '#424242' }}>{t ? t(label) : label}</FormLabel>}
      <RadioGroup {...radioProps}>
        {props.options.map((option: SelectOption, index: number) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio size="small" disabled={option.disabled} />}
            label={t ? t(option.label) : option.label}
            disabled={props.disabled}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{t ? t(helperText) : helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(BaseRadioGroup);

import { setLocale } from 'yup';

const formValidationCallback = (
  _: unknown,
  t: (key: string, ...args: Array<Record<string, unknown>>) => string
) => {
  setLocale({
    mixed: {
      default: t('validation:mixed.default'),
      required: t('validation:mixed.required'),
      oneOf: t('validation:mixed.oneOf'),
      notOneOf: t('validation:mixed.notOneOf'),
      defined: t('validation:mixed.defined'),
    },
    string: {
      length: t('validation:string.length'),
      min: t('validation:string.min'),
      max: t('validation:string.max'),
      matches: t('validation:string.matches'),
      email: t('validation:string.email'),
      url: t('validation:string.url'),
      uuid: t('validation:string.uuid'),
      trim: t('validation:string.trim'),
      lowercase: t('validation:string.lowercase'),
      uppercase: t('validation:string.uppercase'),
    },
    number: {
      min: t('validation:number.min'),
      max: t('validation:number.max'),
      lessThan: t('validation:number.lessThan'),
      moreThan: t('validation:number.moreThan'),
      positive: t('validation:number.positive'),
      negative: t('validation:number.negative'),
      integer: t('validation:number.integer'),
    },
    date: {
      min: t('validation:date.min'),
      max: t('validation:date.max'),
    },
    boolean: {
      isValue: t('validation:boolean.isValue'),
    },
    object: {
      noUnknown: t('validation:object.noUnknown'),
    },
    array: {
      min: t('validation:array.min'),
      max: t('validation:array.max'),
      length: t('validation:array.length'),
    },
  });
};

export default formValidationCallback;

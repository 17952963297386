import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  SxProps,
  Theme,
} from '@mui/material';

export type CheckboxesOptions = {
  label: string;
  value: string;
  title?: string;
};

export type CheckboxesInputProps = Pick<
  CheckboxProps,
  'value' | 'checked' | 'readOnly' | 'disabled' | 'required'
> & {
  label?: string;
  sx?: SxProps<Theme>;
  value: any;
  onChange: (...event: any[]) => void;
  options: Array<CheckboxesOptions>;
  helperText?: string;
};

const CheckboxesInput = (props: CheckboxesInputProps) => {
  const { value, onChange, sx } = props;

  return (
    <FormControl
      className="CheckboxesField-root"
      required={props.required}
      sx={{
        alignContent: 'space-between',
        ...sx,
      }}
    >
      <FormLabel>{props.label}</FormLabel>
      <Grid container spacing={2}>
        {props.options.map((option, index) => (
          <Grid key={option.value} item sm={12} md={6} lg={4} xl={3}>
            <FormControlLabel
              key={index}
              label={option.label}
              control={
                <Checkbox
                  title={option.label}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  value={option.value}
                  checked={value.includes(option.value)}
                  inputProps={{
                    readOnly: props.readOnly,
                    disabled: props.readOnly || props.disabled,
                  }}
                  onChange={(event) => {
                    const values = [...value];

                    if (event.target.checked) {
                      onChange([...values, event.target.value]);
                    } else {
                      values.splice(value.indexOf(event.target.value), 1);

                      onChange([...values]);
                    }
                  }}
                />
              }
            />
          </Grid>
        ))}
      </Grid>

      {props.helperText && <FormHelperText sx={{ mx: 2 }}>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxesInput;

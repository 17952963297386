import { FormLabel, FormHelperText, RatingProps, Stack, FormControl } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Rating } from '@mui/material';

export type RatingInputProps = RatingProps & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  required?: boolean;
};

const RatingInput = (props: RatingInputProps, ref: any) => {
  const { label, helperText, direction, spacing, sx, required, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack
      direction={formDirection}
      spacing={formSpacing}
      sx={{
        ...sx,
      }}
    >
      {label && (
        <FormLabel
          sx={{
            color: '#424242',
            '& .MuiFormLabel-asterisk': {
              color: 'error.main',
            },
          }}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <FormControl size="small">
        <Rating ref={ref} {...inputProps} />
      </FormControl>
      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(RatingInput);

import { StepIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  HourglassEmptyOutlined,
  CheckOutlined,
  Inventory2Outlined,
  LocalShippingOutlined,
  InputOutlined,
  ReceiptLongOutlined,
  HighlightOffOutlined,
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useBreakpoints } from 'hooks';

export const OrderStatusIcon = {
  WAITING: HourglassEmptyOutlined,
  CONFIRMED: CheckOutlined,
  PACKED: Inventory2Outlined,
  SHIPPING: LocalShippingOutlined,
  DELIVERED: InputOutlined,
  RECEIVED: ReceiptLongOutlined,
  CANCEL: HighlightOffOutlined,
};

type Props = StepIconProps & {
  status: keyof typeof OrderStatusIcon;
};

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
  breakpoints: any;
}>(({ theme, ownerState, breakpoints }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: breakpoints.md ? 42 : 36,
  height: breakpoints.md ? 42 : 36,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => {
  return {
    [`& .${stepConnectorClasses.lineHorizontal}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.lineHorizontal}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  };
});

const CustomStepIcon = (props: Props) => {
  const breakpoints = useBreakpoints();
  const { active, completed, status } = props;
  const Icon = OrderStatusIcon[status];

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} breakpoints={breakpoints}>
      <Icon
        sx={{
          fontSize: {
            xs: 18,
            sm: 20,
            md: 30,
          },
        }}
      />
    </ColorlibStepIconRoot>
  );
};

export default CustomStepIcon;

import { Chip } from '@mui/material';

type Props = {
  status:
    | 'AVAILABLE'
    | 'BOOKING'
    | 'COMMITTED'
    | 'SOLD'
    | 'RETURN'
    | 'INTERNAL'
    | 'UNAVAILABLE'
    | 'QUARANTINE'
    | 'DAMAGED'
    | 'DELIVERED';
  label?: string;
  maxWidth?: number;
};

const StatusColorMap = {
  AVAILABLE: 'info',
  BOOKING: 'primary',
  COMMITTED: 'primary',
  SOLD: 'primary',
  RETURN: 'warning',
  UNAVAILABLE: 'warning',
  QUARANTINE: 'success',
  DAMAGED: 'success',
  INTERNAL: 'success',
  DELIVERED: 'success',
};

const StockStatusChip = (props: Props) => {
  return (
    <Chip
      sx={{
        fontWeight: 'bold',
        width: '100%',
        maxWidth: props.maxWidth || 100,
        textTransform: 'capitalize',
      }}
      size="small"
      label={props.label || props.status}
      color={StatusColorMap[props.status] as any}
      variant="filled"
    />
  );
};

export default StockStatusChip;

import { useState, useCallback } from 'react';

const useDisclose = (initState?: boolean) => {
  const [isOpen, setIsOpen] = useState(initState ?? false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((state) => !state), []);

  return { isOpen, open, close, toggle };
};

export default useDisclose;

import { useMemo } from 'react';

import { Box } from '@mui/material';

import { ToastPosition } from './types';
import { useToastState } from './createToastContext';
import ToastMessage from './ToastMessage';

type Props = {
  position: ToastPosition;
  duration: number;
};

const ToastMessageContainer = (props: Props) => {
  const { messages } = useToastState();

  const [top, left, bottom, right] = useMemo(() => {
    switch (props.position) {
      case 'top-left':
        return [2, 2, 'auto', 'auto'];

      case 'top-center':
        return [2, '50%', 'auto', 'auto'];

      case 'top-right':
        return [2, 'auto', 'auto', 2];

      case 'bottom-left':
        return ['auto', 2, 2, 'auto'];

      case 'bottom-center':
        return ['auto', '50%', 2, 'auto'];

      case 'bottom-right':
        return ['auto', 'auto', 2, 2];

      default:
        return [2, 'auto', 'auto', 2];
    }
  }, [props.position]);

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: (theme) => theme.zIndex.modal + 1,
        top: (theme) => (Number.isInteger(top) ? theme.spacing(Number(top)) : top),
        left: (theme) => (Number.isInteger(left) ? theme.spacing(Number(left)) : left),
        right: (theme) => (Number.isInteger(right) ? theme.spacing(Number(right)) : right),
        bottom: (theme) => (Number.isInteger(bottom) ? theme.spacing(Number(bottom)) : bottom),
      }}
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <ToastMessage
            sx={{
              mb: 2,
            }}
            key={message.id}
            {...message}
            duration={props.duration}
          />
        ))}
    </Box>
  );
};

export default ToastMessageContainer;

import { Fragment } from 'react';
import { FormLabel, Stack, TextField, Box } from '@mui/material';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export type BaseRangePickerProps = DateRangePickerProps<Date, Date> & {
  label?: string;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  t?: (key: string, context?: Record<string, unknown>) => string;
};

function BaseRangePicker(props: BaseRangePickerProps) {
  const { t, label, direction, spacing, placeholder, startText, endText, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack direction={formDirection} spacing={formSpacing}>
      {label && <FormLabel sx={{ color: '#424242' }}>{t ? t(label) : label}</FormLabel>}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: startText, end: endText }}
      >
        <DateRangePicker
          label={placeholder}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          {...inputProps}
          renderInput={(startProps, endProps) => (
            <Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> To </Box>
              <TextField {...endProps} />
            </Fragment>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}

export default BaseRangePicker;

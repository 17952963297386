import { forwardRef, ReactNode, useMemo, useRef } from 'react';

import { FormLabel, FormHelperText, Stack, FormControl } from '@mui/material';
import ReactQuill, { ReactQuillProps, Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FormatsImage = Quill.import('formats/image');

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export type HtmlInputProps = ReactQuillProps & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
  required?: boolean;
  imageHandler: (file: FileList) => Promise<{ id: string; url: string } | undefined>;
};

class OverrideImage extends FormatsImage {
  static create(data: any) {
    const node = super.create(data);
    node.setAttribute('src', data.src);
    node.setAttribute('id', data.id);
    return node;
  }

  static value(domNode: any) {
    let { src, id } = domNode.dataset;

    if (!src) {
      src = domNode?.src;
    }

    if (!id) {
      id = domNode?.id;
    }

    return { src, id };
  }
}

Quill.register({ 'formats/image': OverrideImage });
const HtmlInput = (props: HtmlInputProps, ref: React.Ref<HTMLDivElement>) => {
  const { label, helperText, direction, spacing, style, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;
  const quillRef = useRef<ReactQuill>();

  const imageHandler = () => {
    console.log('run nè');
    if (!quillRef.current) {
      return;
    }

    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (!input.files?.length) {
        return;
      }

      const res = await props.imageHandler(input.files);

      if (res?.id && res?.url) {
        const range = quillRef.current!.getEditorSelection();

        editor.insertEmbed(range!.index, 'image', {
          src: res.url,
          id: res.id,
        });
      }
    };
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  return (
    <Stack
      direction={formDirection}
      spacing={formSpacing}
      sx={{
        ...style,
      }}
      ref={ref}
    >
      {label && (
        <FormLabel sx={{ color: '#424242' }} required={props.required}>
          {label}
        </FormLabel>
      )}
      <FormControl
        size="small"
        sx={{
          '& .ql-container': {
            minHeight: 200,
          },
          '& .ql-editor': {
            maxHeight: 600,
          },
          border: helperText ? '1px solid red' : 'none',
        }}
      >
        <ReactQuill
          {...inputProps}
          theme="snow"
          modules={modules}
          formats={formats}
          ref={quillRef as any}
        />
      </FormControl>
      {helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText>}
    </Stack>
  );
};

export default forwardRef(HtmlInput);

import {
  FormLabel,
  FormHelperText,
  OutlinedInput,
  InputProps,
  Stack,
  FormControl,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';

export type BaseInputProps = InputProps & {
  label?: ReactNode;
  direction?: 'horizontal' | 'vertical';
  spacing?: number;
  helperText?: string;
};

const BaseInput = (props: BaseInputProps, ref: React.Ref<HTMLDivElement>) => {
  const { label, helperText, direction, spacing, sx, value, required, ...inputProps } = props;
  const formDirection = direction === 'horizontal' ? 'row' : 'column';
  const formSpacing = spacing ?? formDirection === 'column' ? 1 : 2;

  return (
    <Stack
      direction={formDirection}
      spacing={formSpacing}
      sx={{
        ...sx,
      }}
    >
      {label && (
        <FormLabel
          sx={{
            color: '#424242',
            '& .MuiFormLabel-asterisk': {
              color: 'error.main',
            },
          }}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <FormControl
        size="small"
        sx={{
          '& .MuiInputBase-multiline': {
            p: 0,
          },
        }}
      >
        <OutlinedInput
          ref={ref}
          sx={{
            borderRadius: 1,
            '& .MuiOutlinedInput-input': { p: 1 },

            '& .MuiFormHelperText-root': {
              marginLeft: 0,
            },
          }}
          value={value ?? ''}
          {...inputProps}
        />
      </FormControl>
      {helperText && (
        <FormHelperText sx={{ color: inputProps.error ? 'red' : 'gray' }}>
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default forwardRef(BaseInput);

import { forwardRef } from 'react';

import { Box, Select, MenuItem } from '@mui/material';

import BaseInput, { type BaseInputProps } from './BaseInput';

export type BaseSelectProps = BaseInputProps & {
  options: SelectOption[];
  multiple?: boolean;
  t?: (key: string) => string;
};

const BaseSelect = (props: BaseSelectProps, ref: any) => {
  const { options, sx, t, ...restInputProps } = props;

  return (
    <Select
      ref={ref}
      multiple={props.multiple}
      sx={{
        borderRadius: 1,
        '& .MuiSelect-select': { py: 1 },
        ...sx,
      }}
      displayEmpty
      input={<BaseInput {...restInputProps} />}
      renderValue={(selected: any) => {
        if (selected?.length === 0) {
          return (
            <Box
              sx={{
                color: (theme) => theme.palette.grey[300],
                fontWeight: 300,
              }}
            >
              {restInputProps.placeholder}
            </Box>
          );
        }

        if (props.multiple) {
          return options
            .filter((o) => selected.includes(o.value))
            .map((o) => o.label)
            .join(', ');
        }

        return options.find((x) => x.value === selected)?.label ?? '';
      }}
      MenuProps={{
        style: {
          maxHeight: 400,
        },
      }}
    >
      {options.map((option: SelectOption) => (
        <MenuItem key={option.value} value={option.value}>
          {t ? t(option.label) : option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default forwardRef(BaseSelect);
